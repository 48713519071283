import React, { Component } from "react";
import { GET, POST } from "../../helpers/request_helper";
import { Loader } from "../../component/misc/loader";
import parse from 'html-react-parser';
import { GAevent } from "../../helpers/analytics";

class ContactTwo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rnName: '',
            rnEmail: '',
            rnSubject: '',
            rnMessage: '',
            btntext: 'Submit'
        };
    }

    getData() {
        //load email block
        GET('/jsonapi/block_content/text_image_block?filter[drupal_internal__id]=5&include=field_image', {}, {})
            .then(resp => {
                this.setState({
                    email_list: resp.data
                })
            })

        // //load about block
        GET('/jsonapi/block_content/text_image_block?filter[drupal_internal__id]=6&include=field_image', {}, {})
            .then(resp => {
                this.setState({
                    location_block: resp.data
                })
            })
    }

    componentDidMount() {
        this.getData()
        window.scrollTo({ top: 0, behavior: 'smooth' });

    }

    submit_form(e) {
        e.preventDefault()
        if (this.state.rnName && this.state.rnEmail && this.state.rnMessage && this.state.rnSubject) {
            this.setState({
                btntext: 'Sending....'
            })
            let body = {
                "contact_form": [{ "target_id": "feedback" }],
                "mail": [{ "value": this.state.rnEmail }],
                "name": [{ "value": this.state.rnName }],
                "message": [{ "value": this.state.rnMessage }],
                "subject": [{ "value": this.state.rnSubject }]
            }
            POST(`/contact_message?_format=json`, {}, {}, body)
                .then(res => {
                    GAevent("form", "submitted")
                    alert("Details Sent")
                    this.setState({
                        rnName: '',
                        rnEmail: '',
                        rnSubject: '',
                        rnMessage: '',
                        btntext: 'Submit'
                    })
                })
                .catch(err => {
                    alert("Something went wrong.Please try later")
                })
        }
        else {
            alert("Enter all Details")
        }
    }

    render() {
        return (
            <div className="contact-form--1">
                {this.state.btntext !== 'Submit' ? <Loader /> : null}
                <div className="">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-6 order-1 order-lg-1">
                            {/* <div className="section-title text-left mb--50">
                                <h2 className="title">Contact Us.</h2>
                                <p className="description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto cupiditate aperiam neque.</p>
                            </div> */}
                            <div className="form-wrapper">
                                <form onSubmit={(e) => this.submit_form(e)} className="col-12">
                                    <label htmlFor="item01">
                                        <input
                                            type="text"
                                            name="name"
                                            id="item01"
                                            value={this.state.rnName}
                                            onChange={(e) => { this.setState({ rnName: e.target.value }); }}
                                            placeholder="Your Name *"
                                        />
                                    </label>

                                    <label htmlFor="item02">
                                        <input
                                            type="text"
                                            name="email"
                                            id="item02"
                                            value={this.state.rnEmail}
                                            onChange={(e) => { this.setState({ rnEmail: e.target.value }); }}
                                            placeholder="Your email *"
                                        />
                                    </label>

                                    <label htmlFor="item03">
                                        <input
                                            type="text"
                                            name="subject"
                                            id="item03"
                                            value={this.state.rnSubject}
                                            onChange={(e) => { this.setState({ rnSubject: e.target.value }); }}
                                            placeholder="Write a Subject"
                                        />
                                    </label>
                                    <label htmlFor="item04">
                                        <textarea
                                            type="text"
                                            id="item04"
                                            name="message"
                                            value={this.state.rnMessage}
                                            onChange={(e) => { this.setState({ rnMessage: e.target.value }); }}
                                            placeholder="Your Message"
                                        />
                                    </label>
                                    <button className="rn-button-style--2 btn-solid" type="submit" value="submit" name="submit" id="mc-embedded-subscribe">{this.state.btntext}</button>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-6 order-2 order-lg-2">
                            <div className="thumbnail ">
                                {/* Start Single Address  */}
                                {this.state.email_list ?
                                    <div className="col-12">
                                        <div className="rn-address">
                                            {/* <div className="icon">
                                         <FiMail />
                                     </div> */}
                                            <div className="inner">
                                                <h4 className="title">{this.state.email_list[0].attributes.field_title}</h4>
                                                {parse(this.state.email_list[0].attributes.body.processed)}
                                            </div>
                                        </div>
                                    </div>
                                    : <Loader />}

                                {/* End Single Address  */}

                                {/* Start Single Address  */}
                                {this.state.location_block ?

                                    <div className="col-12">
                                        <div className="rn-address">
                                            {/* <div className="icon">
                                            <FiMapPin />
                                        </div> */}
                                            <div className="inner">
                                                <h4 className="title">{this.state.location_block[0].attributes.field_title}</h4>
                                                <a target="_blank" href={this.state.location_block[0].attributes.field_button.uri}>
                                                    {parse(this.state.location_block[0].attributes.body.processed)}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    : <Loader />}

                                {/* End Single Address  */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ContactTwo;