import React, { Component } from "react";
import { FiCast } from "react-icons/fi";
import parse from 'html-react-parser';
import { GET } from "../../helpers/request_helper";
import { Loader } from "../../component/misc/loader";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Link } from "react-router-dom";

class ServiceThree extends Component {
    state = {}
    getData() {
        GET('/jsonapi/taxonomy_term/services', {}, {})
            .then(resp => {
                console.log("resp", resp)
                this.setState({
                    services: resp.data
                })
            })
    }

    componentDidMount() {
        this.getData()
    }
    render() {
        const { column } = this.props;
        return (
            <React.Fragment>

                <div className="row">
                    {this.state.services ?
                        this.state.services.map((val, i) => (
                            <div className={`${column}`} key={i}>
                                <div className="service service__style--2">
                                    <div className="icon">
                                        <FiCast />
                                    </div>
                                    {val.attributes.description ?
                                        <div className="content">
                                            <Link to="/contact">
                                                <h3 className="title">{val.attributes.name}</h3>
                                            </Link>
                                            <p>
                                                {parse(val.attributes.description.processed.length > 60 ? val.attributes.description.processed.slice(0, 60) + "..." : val.attributes.description.processed)}
                                                {val.attributes.description.processed.length > 60 ?
                                                    <Popup modal trigger={<span className="read-more"> Read More</span>} position="right center">
                                                        <div>{parse(val.attributes.description.processed)}</div>
                                                    </Popup> : ''}
                                            </p>
                                        </div>
                                        : ''}
                                </div>
                            </div>
                        ))
                        : <Loader />}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
