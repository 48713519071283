import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
import { GET } from "../helpers/request_helper";
import { Loader } from "../component/misc/loader";
import parse from 'html-react-parser';
import { IMG_BASE } from "../helpers/base";
import { GApageView } from "../helpers/analytics";

class BlogDetails extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }

    getData() {
        GApageView(`news-details/${this.props.match.params.id}`)

        GET('/jsonapi/node/article?include=field_image&filter[drupal_internal__nid]=' + this.props.match.params.id, {}, {})
            .then(resp => {
                console.log("resp4article", resp)
                this.setState({
                    article: resp
                })
            })

    }

    componentDidMount() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        this.getData()
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='News Details' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                {this.state.article ?
                    <div className="container mb--60">
                        <div className="service-area">
                            <div className="">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title mt--30 mb--30">
                                            <h2>{this.state.article.data[0].attributes.title}</h2>
                                            {/* <p>There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.</p> */}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        {this.state.article.data[0].relationships.field_image.data ?
                            <div className="">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="text-center news-img">
                                            <img alt="news" src={IMG_BASE + this.state.article.included[0].attributes.uri.url} />
                                            {/* <p>There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.</p> */}
                                        </div>
                                    </div>

                                </div>
                            </div>
                            : ''}

                        {/* Start Blog Details */}
                        {this.state.article.data[0].attributes.body ?
                            <div className="rn-blog-details bg_color--1">
                                <div className="">
                                    <div>
                                        <div>
                                            <div className="inner-wrapper">
                                                <div>
                                                    <p>
                                                        {parse(this.state.article.data[0].attributes.body.value)}
                                                    </p>
                                                   
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : ''}
                    </div>
                    : <Loader />}
                {/* End Blog Details */}

                {/* Start BLog Comment Form  */}

                {/* End BLog Comment Form  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <FooterTwo />

            </React.Fragment>
        )
    }
}
export default BlogDetails;