import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiChevronUp } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
import { GET } from "../helpers/request_helper";
import { Link } from "react-router-dom";
import dummy from '../../vga.png';
import { BASE } from "../helpers/base";
import { GApageView } from "../helpers/analytics";
import parse from 'html-react-parser';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

class Subsidiaries extends Component {
    state = {}

    getData() {
        GET('/api/subsidiaries', {}, {})
            .then(resp => {
                console.log("resp", resp)
                this.setState({
                    article_block: resp
                })
            })
    }

    componentDidMount() {
        GApageView("subsidiaries")
        this.getData()
    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Subsidiaries' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                <div className="service-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Subsidiaries</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row container service-one-wrapper">
                            {this.state.article_block ?
                                <div className="row mt--60">
                                    {this.state.article_block.map((value, i) => (
                                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                            <div className="blog blog-style--1">
                                                <div className="thumbnail">
                                                    {value.field_link ?
                                                        <a className="disable-anchor" rel="noopener noreferrer">
                                                            {value.field_image ?
                                                                <img className="w-100" src={BASE + value.field_image} alt="Blog Images" />
                                                                :
                                                                <img className="w-100" src={dummy} alt="Blog Images" />
                                                            }
                                                        </a>
                                                        :
                                                        <Link to={value.field_link ? value.field_link : "/contact"}>
                                                            {value.field_image ?
                                                                <img className="w-100" src={value.field_image} alt="Blog Images" />
                                                                :
                                                                <img className="w-100" src={dummy} alt="Blog Images" />
                                                            }
                                                        </Link>
                                                    }

                                                </div>
                                                <div className="content sub">
                                                    {/* <p className="blogtype">{value.attributes.title}</p> */}
                                                    <h4 className="title white-title">{value.field_title_1}</h4>
                                                    <p className="sub-body">{parse(value.field_body.length > 60 ? value.field_body.slice(0, 60) + "..." : value.field_body)}
                                                        {value.field_body.length > 60 ?
                                                            <Popup modal trigger={<span className="read-more"> Read More</span>} position="right center">
                                                                <div>{parse(value.field_body)}</div>
                                                            </Popup> : ''}</p>
                                                    <div className="blog-btn">
                                                        {/* <a className="rn-btn text-white" href="/blog-details">Read More</a> */}
                                                        {value.field_link ?
                                                            <a target="_blank" href={value.field_link} className="rn-btn text-white">Contact</a>
                                                            :
                                                            <Link to={value.field_link ? value.field_link : "/contact"} className="rn-btn text-white">Contact</Link>
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                : ''}

                        </div>
                    </div>
                </div>
                {/* End Service Area */}



                {/* Start Footer Style  */}
                <FooterTwo />
                {/* End Footer Style  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}


            </React.Fragment>
        )
    }
}
export default Subsidiaries;