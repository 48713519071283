import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ContactTwo from "../elements/contact/ContactTwo";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
import { GApageView } from "../helpers/analytics";

class Contact extends Component{
    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };

    componentDidMount(){
        GApageView("contact")
    }
   
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Contact Us' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                <div className="service-area pb--30 pt--60  bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Contact Us</h2>
                                    {/* <p>There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              


                {/* Start Contact Top Area  */}
                <div className="rn-contact-top-area  bg_color--5">
                    <div className="container">
                        {/* Start Contact Page Area  */}
                        <div className="rn-contact-page  bg_color--1">
                            <ContactTwo />
                        </div>
                        {/* End Contact Page Area  */}

                    </div>
                </div>
                {/* End Contact Top Area  */}

        

              

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <FooterTwo />
                
            </React.Fragment>
        )
    }
}
export default Contact