import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import BlogList from "../elements/blog/BlogList";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
import { GApageView } from "../helpers/analytics";

class Blog extends Component {
    // state = {
    //     article_block: null
    // }
    // getData() {
    //     GET('/jsonapi/node/article?include=field_image', {}, {})
    //         .then(resp => {
    //             console.log("resp4", resp)
    //             this.setState({
    //                 article_block: resp
    //             })
    //         })

    // }

    componentDidMount() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        GApageView("news")


    }


    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Blog' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                {/* Start Breadcrump Area */}
                <div className="service-area ptb--60">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>News</h2>
                                    {/* <p>There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.</p> */}
                                </div>
                            </div>
                            <div className="rn-blog-area mt--30">
                                <div className="container">
                                    <BlogList />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <Breadcrumb title={'Latest News'} /> */}
                {/* End Breadcrump Area */}


                {/* Start Blog Area */}
                {/* {this.state.article_block? */}

                {/* : <Loader />} */}

                {/* End Blog Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <FooterTwo />

            </React.Fragment>
        )
    }
}
export default Blog;
