import React from 'react';
import { FaTwitter, FaInstagram, FaFacebookF, FaLinkedinIn,FaPhone } from "react-icons/fa";
import { Link } from 'react-router-dom';

const SocialShare = [
    { Social: <FaFacebookF />, link: 'https://www.facebook.com/aiclonline' },
    { Social: <FaPhone />, link: 'tel:98461319696' },
    { Social: <FaInstagram />, link: 'https://www.instagram.com/' },
    { Social: <FaTwitter />, link: 'https://twitter.com/' },
]

const FooterTwo = () => {
    return (
        <div className="footer-style-2 ptb--30 border-top" >
            <div className="wrapper plr--50 plr_sm--20">
                <div className="row align-items-center justify-content-between">
                    <nav className="mainmenunav d-lg-block">
                        <ul className="mainmenu">
                            <li><Link to="/reports">Reports & Downloads</Link></li>
                            <li><Link to="/news">News</Link></li>
                            <li ><Link to="/about/team">Managing Team</Link></li>
                            <li><Link to="/contact" >Contact</Link></li>
                        </ul>
                    </nav>
                </div>
                <div className="row align-items-center justify-content-between">
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className="inner">
                            <div className="logo text-center text-sm-left mb_sm--20">
                                <a href="/">
                                    <img src="/assets/images/logo/aicl-logo-full.png" alt="AICL LOGO" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className="inner text-center">
                            <ul className="social-share rn-lg-size d-flex justify-content-center liststyle">
                                {SocialShare.map((val, i) => (
                                    <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                        <div className="inner text-lg-right text-center mt_md--20 mt_sm--20">
                            <div className="text">
                                <p>Copyright © 2020 AICL. All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default FooterTwo;