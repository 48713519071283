import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiCast, FiChevronUp } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
import { GET } from "../helpers/request_helper";
import { Loader } from "../component/misc/loader";
import parse from 'html-react-parser';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Link } from "react-router-dom";
import { GApageView } from "../helpers/analytics";

class Service extends Component {
    state = {}
    getData() {
        GET('/jsonapi/taxonomy_term/services', {}, {})
            .then(resp => {
                console.log("resp", resp)
                this.setState({
                    services: resp.data
                })
            })
    }

    componentDidMount() {
        GApageView("service")
        this.getData()
    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Service' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                {/* <Breadcrumb title={'Service'}   /> */}
                {/* End Breadcrump Area */}

                {/* Start Service Area */}
                <div className="service-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Services</h2>
                                    {/* <p>There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.</p> */}
                                </div>
                            </div>
                        </div>
                        <div className="row service-one-wrapper">
                            {this.state.services ?
                                this.state.services.map((val, i) => (
                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                <FiCast />
                                            </div>
                                            {val.attributes.description ?
                                                <div className="content">
                                                    <Link to="/contact">
                                                        <h3 className="title">{val.attributes.name}</h3>
                                                    </Link>
                                                    <p>
                                                        {parse(val.attributes.description.processed.length > 60 ? val.attributes.description.processed.slice(0, 60) + "..." : val.attributes.description.processed)}
                                                        {val.attributes.description.processed.length > 60 ?
                                                            <Popup modal trigger={<span className="read-more"> Read More</span>} position="right center">
                                                                <div>{parse(val.attributes.description.processed)}</div>
                                                            </Popup> : ''}
                                                    </p>
                                                </div>
                                                : ''}
                                        </div>
                                    </div>
                                ))
                                : <Loader />}

                        </div>
                    </div>
                </div>
                {/* End Service Area */}



                {/* Start Footer Style  */}
                <FooterTwo />
                {/* End Footer Style  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}


            </React.Fragment>
        )
    }
}
export default Service;