// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

// Create Import File
import './index.scss';

// Common Layout
// import Layout from "./component/common/App";


// Home layout
import Business from './home/Business';


// Element Layout
import Service from "./elements/Service";
import About from "./elements/About";
import Contact from "./elements/Contact";

import Blog from "./elements/Blog";
import BlogDetails from "./elements/BlogDetails";
import error404 from "./elements/error404";import { BrowserRouter, Switch, Route  } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import Subsidiaries from './elements/Subsidiaries';
import Reports from './elements/Reports';
import { initGA } from './helpers/analytics';

class Root extends Component{
    componentDidMount(){
        initGA()
    }
    render(){
        return(
            <BrowserRouter basename={'/'}>
                <Switch>
                    <Route exact path={`${process.env.PUBLIC_URL}/`} component={Business}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/service`} component={Service}/>   
                    <Route exact path={`${process.env.PUBLIC_URL}/subsidiaries`} component={Subsidiaries}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/reports`} component={Reports}/>                    
                    <Route exact path={`${process.env.PUBLIC_URL}/contact`} component={Contact}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/about`} component={About}/>        
                    <Route exact path={`${process.env.PUBLIC_URL}/about/team`} component={About}/>                                
                    <Route exact path={`${process.env.PUBLIC_URL}/news`} component={Blog}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/news-details/:id`} component={BlogDetails}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/subsidiaries/:id`} component={BlogDetails}/>
                    <Route path={`${process.env.PUBLIC_URL}/404`} component={error404}/>
                    <Route component={error404}/>
                </Switch>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();