import ReactGA from 'react-ga';

export const initGA = () => {       
    ReactGA.initialize('UA-54309354-31'); // put your tracking id here
} 

export const GApageView = (page) => {   
    ReactGA.pageview(page);   
}

export const GAevent = (categoryName, eventName) => {
    ReactGA.event({       
        category: categoryName,  // Required
        action: eventName       // Required  
    });   
}