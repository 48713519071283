import React, { Component , Fragment } from "react";
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import { GET } from "../../helpers/request_helper";

class CounterOne extends Component{
    state = {
        didViewCountUp: false
    };
    onVisibilityChange = isVisible => {
        if (isVisible) {
            this.setState({didViewCountUp: true});
        }
    }
    getData() {
        GET('/api/about/stats', {}, {})
            .then(resp => {
                console.log(resp)
                this.setState({
                    stat: resp
                })
            })
    }

    componentDidMount() {
        this.getData()
    }

    render(){
        return(
            <Fragment>
                <div className="row">
                    {this.state.stat?
                    this.state.stat.map( (value, index) => (
                        <div className="counterup_style--1 col-lg-4 col-md-4 col-sm-6 col-12" key={index}>
                            <h5 className="counter">
                                <VisibilitySensor onChange={this.onVisibilityChange} offset={{top:10}} delayedCall>
                                    <CountUp end={this.state.didViewCountUp ? Number(value.field_title_1) : 0} />
                                </VisibilitySensor>
                            </h5>
                            <p className="description">{value.field_subtitle_1}</p>
                        </div>
                    ))
                    :''
                    }
                </div>
            </Fragment>
        )
    }
}
export default CounterOne;