import React from 'react'
import dummy from '../../dummy.png';
import { IMG_BASE } from '../../helpers/base';

const TeamTwo = (props) => {
    return (
        <div className="row">
            {props.management.map((value, i) => (
                <div className={`${props.column}`} key={i}>
                    <div className={`team-static ${props.teamStyle}`}>
                        <div className="thumbnail">
                            {value.field_image ?
                                <img src={IMG_BASE + value.field_image} alt="Blog Images" />
                                :
                                <img src={dummy} alt="Blog Images" />
                            }
                        </div>
                        <div className="inner">
                            <div className="content">
                                {value.field_title_1 ?
                                    <h4 className="title">{value.field_title_1}</h4>
                                    : ''}
                                {value.field_subtitle_1 ?
                                    <p className="designation">{value.field_subtitle_1}</p>
                                    : ''}
                                {/* <p className="designation">{value.designation}</p> */}
                            </div>
                            <ul className="social-transparent liststyle d-flex" >
                                {/* {value.socialNetwork.map((social, index) =>
                                    <li key={index}><a href={`${social.url}`}>{social.icon}</a></li>
                                )} */}
                            </ul>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}
export default TeamTwo
