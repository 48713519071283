import React, { Component, Fragment } from "react";
import { IMG_BASE } from "../../helpers/base";
import dummy from '../../../public/assets/images/blog/news-dummy.png';
import { GET } from "../../helpers/request_helper";
import { Link } from "react-router-dom";

class BLogList extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    getImageUrl(id, array) {
        const ids = [id]
        let records = array.filter(i => ids.includes(i.id));
        console.info("records", records);
        return IMG_BASE + records[0].attributes.uri.url
    }

    getData() {
        GET('/jsonapi/node/article?include=field_image', {}, {})
            .then(resp => {
                this.setState({
                    article_block: resp
                })
            })
    }

    componentDidMount() {
        this.getData()
    }

    render() {
        return (
            <Fragment>
                <div className="row">
                    {this.state.article_block ?
                        this.state.article_block.data.map((value, i) => (
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                <div className="blog blog-style--1">
                                    <div className="thumbnail">
                                        <Link to={"/news-details/" + value.attributes.drupal_internal__nid}>
                                            {value.relationships.field_image.data ?
                                                <img className="w-100" src={this.getImageUrl(value.relationships.field_image.data.id, this.state.article_block.included)} alt="Blog Images" />
                                                :
                                                <img className="w-100" src={dummy} alt="Blog Images" />
                                            }
                                        </Link>
                                    </div>
                                    <div className="content">
                                        {/* <p className="blogtype">{value.category}</p> */}
                                        <h4 className="title"><Link to={"/news-details/" + value.attributes.drupal_internal__nid}>{value.attributes.title}</Link></h4>
                                        <div className="blog-btn">
                                            <Link to={"/news-details/" + value.attributes.drupal_internal__nid} className="rn-btn text-white" href="/blog-details">Read More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )) : ''}
                </div>
            </Fragment>
        );
    }
}
export default BLogList;