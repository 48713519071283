import React, { Component, Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import ServiceList from "../elements/service/ServiceList";
import bgimg from '../../public/assets/images/bg/header-bg.jpg'
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
import CallAction from "../elements/callaction/CallAction";
import dummy from '../../vga.png';
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import { GET } from "../helpers/request_helper";
import { Loader } from "../component/misc/loader";
import { IMG_BASE } from "../helpers/base";
import parse from 'html-react-parser';
import { Link } from "react-router-dom";
import { GApageView } from "../helpers/analytics";


class Business extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }

    getImageUrl(id, array) {
        const ids = [id]
        let records = array.filter(i => ids.includes(i.id));
        return IMG_BASE + records[0].attributes.uri.url
    }

    getData() {
        //load top block
        GET('/jsonapi/block_content/text_image_block/c120b038-5ed2-4fdf-aa91-223c2890a5b3', {}, {})
            .then(resp => {
                this.setState({
                    heroblock: resp.data
                })
            })

        //load charitable block
        GET('/jsonapi/block_content/text_image_block?filter[drupal_internal__id]=2&include=field_image', {}, {})
            .then(resp => {
                this.setState({
                    charitable_block: resp
                })
            })

        // //load about block
        GET('/jsonapi/block_content/text_image_block?filter[drupal_internal__id]=3&include=field_image', {}, {})
            .then(resp => {
                this.setState({
                    about_block: resp
                })
            })

        GET('/jsonapi/node/article?include=field_image&page[limit]=3', {}, {})
            .then(resp => {
                this.setState({
                    article_block: resp
                })
            })

    }

    componentDidMount() {
        this.getData()
        GApageView("/")

    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {

        return (
            <Fragment>
                <Helmet pageTitle="AICL - Home" />
                <Header />

                {/* Start Slider Area   */}
                <div className="slider-activation slider-creative-agency" id="home">
                    <div style={{backgroundImage:`url(${bgimg})`}} className="bgthemecolor bgimggreen" >
                        {/* <div className="bgthemecolor " data-black-overlay="0"> */}
                        {/* {SlideList.map((value , index) => ( */}
                        {this.state.heroblock ?
                            <div className="slide slide-style-2 d-flex align-items-center justify-content-center">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className={`inner text-left`}>
                                                {this.state.heroblock.category ? <span>{this.state.heroblock.category}</span> : ''}
                                                {this.state.heroblock.attributes.field_title ? <h1 className="title">{this.state.heroblock.attributes.field_title}</h1> : ''}
                                                {this.state.heroblock.attributes.field_subtitle ? <p className="description">{this.state.heroblock.attributes.field_subtitle}</p> : ''}
                                                {this.state.heroblock.attributes.field_button_text ? <div className="slide-btn"><button className="rn-button-style--2 btn-primary-color">{this.state.heroblock.attributes.field_button_text}</button></div> : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : <Loader />}
                        {/* ))} */}
                    </div>
                </div>
                {/* End Slider Area   */}


                {/* Start Brand Area */}
                {/* <div className="rn-brand-area bg_color--5 ptb--60">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* End Brand Area */}

                {/* Start Service Area  */}
                <div className="service-area creative-service-wrapper ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-center mb--30 mb_sm--0">
                                    <h2 className="title">AICL Services</h2>
                                    {/* <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p> */}
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            <div className="col-lg-12">
                                <ServiceList item="6" column="col-lg-4 col-md-6 col-sm-6 col-12 text-left" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Service Area  */}
                <div className="rn-blog-area pt--120 pb--80 bg_color--5">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-center">
                                    <h2 className="title">Latest News</h2>

                                </div>
                            </div>
                        </div>
                        {this.state.article_block ?
                            <div className="row mt--60">
                                {this.state.article_block.data.map((value, i) => (
                                    <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                        <div className="blog blog-style--1">
                                            <div className="thumbnail">
                                                <Link to={"/news-details/" + value.attributes.drupal_internal__nid}>
                                                    {value.relationships.field_image.data ?
                                                        <img className="w-100" src={this.getImageUrl(value.relationships.field_image.data.id, this.state.article_block.included)} alt="Blog Images" />
                                                        :
                                                        <img className="w-100" src={dummy} alt="Blog Images" />
                                                    }

                                                </Link>
                                            </div>
                                            <div className="content">
                                                {/* <p className="blogtype">{value.attributes.title}</p> */}
                                                <h4 className="title"><Link to={"/news-details/" + value.attributes.drupal_internal__nid}>{value.attributes.title}</Link></h4>
                                                <div className="blog-btn">
                                                    {/* <a className="rn-btn text-white" href="/blog-details">Read More</a> */}
                                                    <Link to={"/news-details/" + value.attributes.drupal_internal__nid} className="rn-btn text-white" href="#">Read More</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            : ''}
                        <div style={{ textAlign: 'center' }}>
                            <button className="rn-button-style--2 btn-solid" type="submit" value="submit" name="submit" id="mc-embedded-subscribe"><Link to='/news'>More News</Link></button>
                        </div>
                    </div>
                </div>
                {/* Start About Area  */}
                {/* <div className="rn-about-area ptb--120 bg_color--5 border-top">
                    <div className="container">
                        {this.state.charitable_block ?
                            <div className="row row--35 align-items-center ">
                                <div className="col-lg-6">
                                    <div className="d-none d-lg-block">
                                        <img className="w-100" src={IMG_BASE + this.state.charitable_block.included[0].attributes.uri.url} alt="About Images" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            {this.state.charitable_block.data[0].attributes.field_title ?
                                                <h2 className="title">{this.state.charitable_block.data[0].attributes.field_title}</h2>
                                                : null}
                                            {this.state.charitable_block.data[0].attributes.body ?
                                                <p>
                                                    {parse(this.state.charitable_block.data[0].attributes.body.processed)}
                                                </p>
                                                : null}
                                        </div>
                                        {this.state.charitable_block.data[0].attributes.field_button ?
                                            <div className="about-button mt--50">
                                                <a className="rn-button-style--2 btn-solid" href="/about">{this.state.charitable_block.data[0].attributes.field_button}</a>
                                            </div>
                                            : null}

                                    </div>
                                </div>
                            </div>
                            : <Loader />}

                    </div>
                </div> */}
                {/* End About Area  */}

                {/* Start About Area  */}
                <div className="rn-about-area ptb--120 bg_color--1 border-top">
                    <div className="container">
                        {this.state.about_block ?
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-6 order-2 order-lg-1">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            {this.state.about_block.data[0].attributes.field_title ?
                                                <h2 className="title">{this.state.about_block.data[0].attributes.field_title}</h2>
                                                : null}
                                            {this.state.about_block.data[0].attributes.body ?
                                                <p>
                                                    {parse(this.state.about_block.data[0].attributes.body.processed)}
                                                </p>
                                                : null}
                                        </div>
                                        {/* <div className="mt--30">
                                            <h4>Lorem ipsum dolor sit.</h4>
                                            <ul className="list-style--1">
                                                {namesItemOne.map((name, index) => {
                                                    return <li key={index}><FiCheck /> {name}</li>;
                                                })}
                                            </ul>
                                        </div> */}
                                        {/* <div className="mt--30">
                                        <h4>Lorem ipsum dolor sit.</h4>
                                        <ul className="list-style--1">
                                            {namesItemTwo.map((name, index) => {
                                                return <li key={ index }><FiCheck /> {name}</li>;
                                            })}
                                        </ul>
                                    </div> */}
                                    </div>
                                </div>
                                <div className="col-lg-6 order-1 order-lg-2">
                                    <div className="position-relative d-none d-lg-block">
                                        <img className="w-500" src={IMG_BASE + this.state.about_block.included[0].attributes.uri.url} alt="About Images" />
                                        {/* <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} /> */}
                                        {/* <button className="video-popup position-top-center theme-color" onClick={this.openModal}><span className="play-icon"></span></button> */}
                                    </div>
                                </div>
                            </div>
                            : <Loader />}
                    </div>
                </div>
                {/* End About Area  */}

                {/* Start call To Action  */}
                <CallAction />
                {/* End call To Action  */}


                {/* Start Blog Area */}
                
                {/* End Blog Area */}



                {/* Start Footer Style  */}
                <FooterTwo />
                {/* End Footer Style  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }
}
export default Business;