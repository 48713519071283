import { BASE } from "./base";
const { default: Axios } = require("axios");

export const GET = async (url, params, headers) => {
    return new Promise((resolve, reject) => {
        try {
            Axios({
                method: 'get',
                url: BASE + url,
                params: params,
                headers: headers
            })
                .then(function (response) {
                    resolve(response.data)
                });
        } catch (error) {
            console.error(error);
        }
    })
}

export const POST = (url, params, headers, body) => {
    return new Promise((resolve, reject) => {
        try {
            Axios({
                method: 'POST',
                url: BASE + url,
                params: params,
                headers: headers,
                data: body
            })
                .then(function (response) {
                    console.log(response.data)
                    resolve(response.data)
                })
                .catch(err => {
                    reject(err);
                })
        } catch (error) {
            reject(error);
        }
    })
}