import React, { Component, createRef } from "react";
import PageHelmet from "../component/common/Helmet";
import CounterOne from "../elements/counters/CounterOne";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
import Team from "../blocks/team/TeamTwo";
import { GET } from "../helpers/request_helper";
import parse from 'html-react-parser';
import { Loader } from "../component/misc/loader";
import { GApageView } from "../helpers/analytics";


class About extends Component {
    constructor(props) {
        super(props);
        console.log(props)
        this.scrollDiv = createRef();
    }
    state = {

    }



    getData() {
        GET('/api/management', {}, {})
            .then(resp => {
                console.log(resp)
                this.setState({
                    management: resp
                })
                if (this.props.location.pathname === "/about/team")
                    this.scrollDiv.current.scrollIntoView({ behavior: 'smooth' });
                else
                    window.scrollTo(0, 0)
            })

        GET('/jsonapi/node/page?filter[drupal_internal__nid]=5', {}, {})
            .then(resp => {
                this.setState({
                    about: resp.data[0]
                })
            })
    }

    componentDidMount() {
        GApageView("about")
        this.getData()
    }


    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='About' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                {/* Start Breadcrump Area */}
                {/* <Breadcrumb title={'About'}   /> */}
                {/* End Breadcrump Area */}

                {/* Start About Area  */}
                {this.state.about ?
                    <div className="rn-about-area bg_color--1">
                        <div className="rn-about-wrapper">
                            <div className="container">
                                <div className="row row--35 align-items-center">
                                    <div className="col-lg-5">
                                        <div className="">
                                            <img className="w-100 d-none d-lg-block" src="/assets/images/about/1.png" alt="About Images" />
                                        </div>
                                    </div>
                                    <div className="col-lg-7">
                                        <div className="about-inner inner">
                                            <div className="section-title">
                                                <h2 className="title">{this.state.about.attributes.title}</h2>
                                                <p className="description">
                                                    {parse(this.state.about.attributes.body.value)}
                                                </p>
                                            </div>
                                            {/* <div className="row mt--30">
                                                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                    <div className="about-us-list">
                                                        <h3 className="title">Who we are</h3>
                                                        <p>There are many vtions of passages of Lorem Ipsum available, but the majority have suffered.</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                    <div className="about-us-list">
                                                        <h3 className="title">Who we are</h3>
                                                        <p>There are many vtions of passages of Lorem Ipsum available, but the majority have suffered.</p>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : <Loader />}

                {/* End About Area  */}

                {/* Start CounterUp Area */}
                <div className="rn-counterup-area mt--50 pb--120 bg_color--1 ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h3 className="fontWeight500">Quick Overview</h3>
                                </div>
                            </div>
                        </div>
                        <CounterOne />
                    </div>
                </div>
                {/* End CounterUp Area */}



                {/* Start Team Area  */}
                {/* <div className="rn-team-area bg_color--1 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-center mb--25">
                                    <h2 className="title">Board Of Directors </h2>                                    
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            
                           

                        </div>
                    </div>
                </div> */}
                {/* End Team Area  */}

                {/* Start Team Area  */}
                <div className="rn-team-area ptb--120 bg_color--1 border-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                                    <h2  ref={this.scrollDiv} className="title">Managing Team</h2>
                                </div>
                            </div>
                        </div>
                        {this.state.management ?
                            <div className="row">
                                <Team management={this.state.management} column="col-lg-3 col-md-6 col-sm-6 col-12 mt--30" teamStyle="" item="8" />
                            </div>
                            : <Loader />}

                    </div>
                </div>
                {/* End Team Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <FooterTwo />

            </React.Fragment>
        )
    }
}
export default About