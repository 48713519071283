import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import { FiCast, FiLayers, FiUsers, FiMonitor, FiChevronUp } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
import { GET } from "../helpers/request_helper";
import pdf from '../../public/assets/images/icons/pdf.png';
import { Link } from "react-router-dom";
import { Loader } from "../component/misc/loader";
import { BASE } from "../helpers/base";
import { GApageView } from "../helpers/analytics";

class Reports extends Component {
    state = {}

    getData() {
        GET('/jsonapi/node/report?include=field_doc', {}, {})
            .then(resp => {
                console.log("resp", resp)
                this.setState({
                    reports: resp
                })
            })
    }

    componentDidMount() {
        GApageView("Reports & Downloads")
        window.scrollTo({ top: 0, behavior: 'smooth' });
        this.getData()
    }
    render() {
        const { column } = this.props;

        return (
            <React.Fragment>
                <PageHelmet pageTitle='Reports' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                {/* <Breadcrumb title={'Service'}   /> */}
                {/* End Breadcrump Area */}

                {/* Start Service Area */}
                <div id="reports" className="service-area  ptb--60 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Reports</h2>
                                    {/* <p>There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.</p> */}
                                </div>
                            </div>
                        </div>
                        <div className="row service-one-wrapper">
                            {this.state.reports ?
                                this.state.reports.data.map((val, i) => (
                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                        <div className="service main-wrapper service__style--2">
                                            {/* <div className="icon">
                                                <FiCast />
                                            </div> */}
                                            <div className="content report">
                                                <h3 className="title">{val.attributes.title}</h3>
                                                {val.attributes.field_year ?
                                                    <p>
                                                        Year: {val.attributes.field_year}
                                                    </p>
                                                    : ''}

                                                {this.state.reports.included ?
                                                    <a href={BASE + this.state.reports.included[i].attributes.uri.url} target="_blank">
                                                        <img src={pdf} alt="pdf" />
                                                        <p>Download</p>
                                                    </a>
                                                    : ''
                                                }
                                            </div>
                                        </div>
                                    </div>
                                ))
                                : <Loader />}


                        </div>
                    </div>
                </div>
                {/* End Service Area */}



                {/* Start Footer Style  */}
                <FooterTwo />
                {/* End Footer Style  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}


            </React.Fragment>
        )
    }
}
export default Reports;